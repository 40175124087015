import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Home from '../views/Patients/Patients.vue'
import InsurancesView from '../views/Insurances.vue'
import DiagnosticsView from '../views/Diagnostics.vue'
import RecordsView from '../views/Records.vue'
import CalculatorView from '../views/Calculator.vue'
import Gallery from '../views/Gallery/Gallery.vue'
import GalleryFile from '../views/Gallery/GalleryFile.vue'
import LoginReport from '../views/Admin/Users/LoginReport.vue'
import PatientList from '../views/Patients/List.vue'
import DoctorsView from '../views/Doctors/List.vue'
import UsersView from '../views/Admin/Users/List.vue'
import NotificationsView from '../views/Admin/Users/Notifications.vue'
import store from '../store'
import InsuranceGeneralReport from '@/components/reports/insurance-general-report.vue'
import InsuranceBFS from '@/components/reports/insurance-bfs-report.vue'
import InsuranceBSS from '@/components/reports/insurance-bss-report.vue'
import {
  getSelectedDoctor,
  isAuthenticated,
  needsADoctor
} from '@/components/admin/users/users.service'
import { CurrentUser } from '@/models'
import CompleteProfile from '@/views/Auth/CompleteProfile.vue'
// import Dashboard from '@/views/Dashboard.vue'
Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/Auth/Login.vue'),
    meta: { needsAuth: false }
  },
  {
    path: '/signUp',
    name: 'SignUp',
    component: () => import('@/views/Auth/SignUp.vue'),
    meta: { needsAuth: false },
    props: true
  },
  {
    path: '/underMaintenance',
    name: 'UnderMaintenance',

    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "underMaintenance" */ '../views/UnderMaintenance.vue'
      )
  },
  {
    path: '/doctorSelection',
    name: 'DoctorSelection',
    component: () => import('@/views/Auth/DoctorSelection.vue')
  },
  {
    path: '/privacy',
    name: 'PrivacyPolicy2',
    meta: { needsAuth: false },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/PrivacyPolicy.vue')
  },
  {
    path: '/termsandconditions',
    name: 'Terms2',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    meta: { needsAuth: false },
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/Terms.vue')
  },
  {
    path: '/',
    redirect: '/dashboard',

    component: () => import('@/views/Layout/Layout.vue'),
    // component: Dashboard,
    children: [
      {
        path: '/dashboard',
        name: 'Dashboard',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "about" */ '../views/Dashboard.vue')
      },
      {
        path: '/patients',
        name: 'PatientsView',
        component: Home
      },
      {
        path: '/completeProfile',
        name: 'CompleteProfile',
        component: CompleteProfile
      },
      {
        path: '/magCalculator',
        name: 'CalculatorView',
        component: CalculatorView
      },
      {
        path: '/doctors',
        name: 'DoctorsView',
        component: DoctorsView
      },
      {
        path: '/admin/users',
        name: 'UsersView',
        component: UsersView
      },
      {
        path: '/admin/notifications',
        name: 'Notifications',
        component: NotificationsView
      },
      {
        path: '/patientList',
        name: 'PatientsListView',
        component: PatientList
      },
      {
        path: '/record/:recordID',
        props: true,
        name: 'RecordsView',
        component: RecordsView
      },
      {
        path: '/insurances',
        name: 'Insurances',
        component: InsurancesView
      },
      {
        path: '/reports/insuranceGeneral',
        name: 'InsuranceGeneralReport',
        component: InsuranceGeneralReport
      },
      {
        path: '/reports/insuranceBFS',
        name: 'InsuranceBFS',
        component: InsuranceBFS
      },
      {
        path: '/reports/insuranceBSS',
        name: 'InsuranceBSS',
        component: InsuranceBSS
      },
      {
        path: '/insuranceAgreements',
        name: 'InsuranceAgreements',
        component: () =>
          import(
            /* webpackChunkName: "about" */ '../views/InsuranceAgreements.vue'
          )
        // component: InsuranceAgreements
      },
      {
        path: '/diagnostics',
        name: 'Diagnostics',
        component: DiagnosticsView
      },
      {
        path: '/gallery',
        name: 'Gallery',
        component: Gallery
      },
      {
        path: '/galleryFile/:id',
        name: 'GalleryFile',
        props: true,
        component: GalleryFile
      },
      {
        path: '/upload',
        name: 'Upload',
        component: () =>
          import(/* webpackChunkName: "about" */ '../views/Upload.vue')
      },
      {
        path: '/admin/loginReport',
        name: 'LoginReport',
        component: LoginReport,
        meta: { needsAuth: true }
      },
      {
        path: '/about',
        name: 'About',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "about" */ '../views/About.vue')
      },
      {
        path: '/privacyPolicy',
        name: 'PrivacyPolicy',
        meta: { needsAuth: false },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "about" */ '../views/PrivacyPolicy.vue')
      },
      {
        path: '/terms',
        name: 'Terms',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        meta: { needsAuth: false },
        component: () =>
          import(/* webpackChunkName: "about" */ '../views/Terms.vue')
      },
      {
        path: '/smartPN/:recordID',
        name: 'SmartPN',
        props: true,
        meta: { needsAuth: true },
        component: () =>
          import(/* webpackChunkName: "smartPN" */ '../views/SmartPN.vue')
      },
      {
        path: '/smartPNHistory/:recordID',
        name: 'SmartPNHistory',
        props: true,
        meta: { needsAuth: true },
        component: () =>
          import(
            /* webpackChunkName: "smartPN" */ '../views/SmartPNHistory.vue'
          )
      },

      {
        path: '/admin/forms',
        name: 'FormsManagement',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "about" */ '../views/Admin/forms.vue')
      },
      {
        path: '/support',
        name: 'Support',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "support" */ '../views/Support/index.vue')
      }
    ]
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    // component:
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
router.beforeEach((to, from, next) => {
  if (
    to.name !== 'Login' &&
    to.name !== 'SignUp' &&
    to.name !== 'PrivacyPolicy2' &&
    to.name !== 'Terms2' &&
    to.name !== 'UnderMaintenance' &&
    !isAuthenticated()
  )
    next({ name: 'Login' })
  else if (to.name === 'Dashboard' && needsADoctor()) {
    next({ name: 'DoctorSelection' })
  } else {
    next()
  }

  if (from.name === 'Login' && to.name === 'Dashboard' && isAuthenticated()) {
    const currentUser: CurrentUser = JSON.parse(
      localStorage.getItem('currentUser')
    )
    // this.currentUser.rmT_UserId
    const userId = currentUser.userId.toString() // Replace this with logic to get the logged-in user's ID
    store.dispatch('app/initSignalR', userId)
    store.dispatch('app/fetchNotifications', currentUser.rmtUserId.toString())
    // this.initSignalR(userId)
  }

  // if (from.name === 'RecordsView') {
  //   // alert(1)
  //   next(false)
  // }
})
export default router
