
import { Component, Vue } from 'vue-property-decorator'

import { namespace } from 'vuex-class'
import Navbar from '@/components/shared/navbar.vue' // @ is an alias to /src
import router from '@/router'

const AppModule = namespace('app')

@Component({
  components: {
    Navbar
  }
})
export default class Layout extends Vue {
  @AppModule.Getter
  public _isLoading!: () => boolean

  @AppModule.Getter
  public _takesLong!: () => boolean

  public currentYear: number = new Date().getFullYear()
  public fields = [
    {
      key: 'id',
      label: 'Record Number'
    },
    {
      key: 'insurance',
      label: 'Insurance'
    },
    {
      key: 'contract',
      label: 'Contract'
    },
    {
      key: 'name',
      label: 'Name'
    },
    {
      key: 'dob',
      label: 'DOB (MM/DD/YYYY)'
    },
    {
      key: 'age',
      label: 'Age'
    },
    {
      key: 'sex',
      label: 'Sex'
    },
    {
      key: 'notes',
      label: 'Notes'
    },
    {
      key: 'notesMagToMD',
      label: 'Notes MAG to MD'
    },
    {
      key: 'statusFirstSemester',
      label: 'Status Billing-First-Semester'
    },
    {
      key: 'statusSecondSemester',
      label: 'Status Billing-Second-Semester'
    },
    {
      key: 'active',
      label: 'Active'
    },
    {
      key: 'raf',
      label: 'RAF Total'
    },
    {
      key: 'audit',
      label: 'Audit Code MAG'
    }
  ]
  public items = [
    {
      id: 10010,
      insurance: 'ARS HUMANO',
      contract: 1234,
      name: 'Johnny Encarnacion',
      dob: '10/06/1992',
      age: 28,
      sex: 'M',
      notes: 'this is a test note.',
      notesMagToMD: 'this is another test note from MAG to MD',
      status: '',
      active: true,
      statusFirstSemester: 'active',
      statusSecondSemester: 'inactive',
      raf: 4.43,
      audit: 1001
    }
  ]

  public insurances = [
    {
      value: 1,
      text: 'ARS HUMANO'
    },
    {
      value: 2,
      text: 'UNIVERSAL'
    }
  ]
  public contracts = [
    {
      text: '12345',
      value: 12345
    },
    {
      text: '125',
      value: 125
    }
  ]
  public genders = [
    {
      value: 1,
      text: 'M'
    },
    {
      value: 2,
      text: 'F'
    }
  ]
  public form = {
    insurance: null,
    contract: null,
    dob: new Date(),
    gender: null,
    notes: null,
    notesMAG: null,
    rafTotal: 4.24,
    auditCode: null,
    firstSemester: true,
    secondSemester: false,
    active: true
  }

  public goTo(route: string) {
    this.smoothscroll()
    router.push({ path: route })
  }
  public smoothscroll() {
    const currentScroll =
      document.documentElement.scrollTop || document.body.scrollTop
    if (currentScroll > 0) {
      window.requestAnimationFrame(this.smoothscroll)
      window.scrollTo(0, currentScroll - currentScroll / 5)
    }
  }
  // created() {
  //   const menu = getMenu()
  //   console.log(menu, 1)
  // }
}
